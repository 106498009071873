// define jQuery
jQuery(function($){

var characterLimit = {
    expendButton: $('.btn-all'),
    textComntainer: $('.view-m-mb'),

    hide_para: function(parentcomp,slicing_chars) {
        var paragraphs = $(parentcomp).find('p');
        var total_length = 0;
        var is_current_sorted = false;
        paragraphs.each(function(index, el) {
            total_length = total_length + $(el).text().length;
            if (total_length >= slicing_chars) {
                var newText = $(el).text()
                var slice_index = $(el).text().length - (total_length - slicing_chars);
                if (slice_index >= 80 && !is_current_sorted) {
                    for (var i = slice_index; i >= 0; i--) {
                        if (newText[i] == ' ') {
                            slice_index = i
                            break;
                        }
                    }
                    newText = newText.slice(0, slice_index)
                    $(el).after('<p class="cloned">' + newText + '<span class="trail_dots">....</span>' + '</p>');
                    is_current_sorted = true

                } else {
                    $(el).addClass('hide');
                    $(paragraphs[index - 1]).append('<span class="trail_dots">....</span>');
                    is_current_sorted = true
                }
                $(el).addClass('hide');
            }
        });
        if (total_length <= slicing_chars) {
            $(parentcomp).find('.btn').addClass('hide');
        }
    },

    contentLimit: function(charlimit) {
        function contentToggle() {
            characterLimit.expendButton.on('click', function(e) {
                e.preventDefault();
                var sec_parent = $(this).parents('.char_height_limit')
                if (sec_parent.hasClass('opened')) {
                    characterLimit.hide_para(sec_parent,charlimit);
                    sec_parent.removeClass('opened')
                } else {
                    $(this).siblings('.cloned').remove();
                    $(this).siblings('.hide').removeClass('hide');
                    sec_parent.find('.trail_dots').remove();
                    sec_parent.addClass('opened')
                }
            });
        }
        contentToggle();
    },

    init: function() {
        $(window).resize(function(event) {
            if ($(window).width() < window.tablet) {
                $('.char_height_limit').each(function() {
                    characterLimit.hide_para(this,390);
                });
            }
        });
        if ($(window).width() < window.tablet) {
            $('.char_height_limit').each(function() {
                characterLimit.hide_para(this,390);
            });
        }
        characterLimit.contentLimit(390);

    }
};
var contentGate = {
	bypassLink: $('#bypasscontentgate'),

	init: function() {
		function initBypassLink() {
			contentGate.bypassLink.click(function(e) {
				e.preventDefault();

				var $this = $(this);
				var $form = $this.closest("form");

				$.ajax({
					type: "post",
					url: this.href,
					data: null,
					cache: false,
					//dataType: 'json',
					//contentType: 'application/json; charset=utf-8',
					success: function(data) {
						$(".thanksmsgajax").html(data);
						$form.siblings('.news-subscriber.thank-msg').show();
						$('body').addClass('subs-thanksmsg');
						$form.hide();

						// See equivalent code in validation.js in validation.validateForms for documenation
						if ($this.data("onsuccess") === "reload" && data.indexOf("ajax-error") == -1)
						{
							let delay = $this.data("onsuccess-delay");
							if (isNaN(delay)) { delay = 3; }
							setTimeout(function () {
								window.location.reload(1);
							}, delay * 1000);
						}
						else if ($this.data("onerror") === "reload" && data.indexOf("ajax-error") != -1)
						{
							let delay = $this.data("onerror-delay");
							if (isNaN(delay)) { delay = 5; }

							setTimeout(function () {
								window.location.reload(1);
							}, delay * 1000);
						}
						else if ($(window).width() < window.tablet)
						{
							$('html,body').animate({
								scrollTop: $("#newssignupform").offset().top
							}, 'slow');
						}
					}
				});

			});
			;
		}
		initBypassLink();
	},
};
var customSelect = {
    scrollContent: $('.select-content'),
    scrollList: $('.client-menu-list'),
    scrollListUl: $('.client-menu-list ul'),
    selectBtn: $('.select-btn'),
    selectBtnContent: $('.select-btn span'),
    selectItem: $('.client-menu-list ul li a'),
    selectLabel: $('.select-btn-content .select-btn span'),
    selectBox: $('.select-btn-content'),
    removeFilterBtn: $('.clients .remove-clients'),
    selectClass: $('.select-wrapper select'),
    body: $('body'),



    searchSelect: function() {

        function selectIntialization(){
            customSelect.selectClass.dropkick({
                mobile: true
            });
            $('.dk-select-options').each(function(index, el) {
                new SimpleBar(el);
            });
        }
        selectIntialization();

        function openSearch() {
            customSelect.selectBtn.click(function(e) {
                e.preventDefault();
                $(this).parent().toggleClass('open');
                customSelect.scrollContent.scrollTop(0);
            });
        }
        openSearch();

        function gettingvalue() {
            customSelect.selectItem.click(function(e) {
                e.preventDefault();
                var text = $(this).html();
                customSelect.selectLabel.html(text);
                customSelect.selectBox.removeClass('open');
                customSelect.removeFilterBtn.removeClass('disable');
            });
        }
        gettingvalue();

        function removeClient() {
            customSelect.removeFilterBtn.click(function() {
                customSelect.selectLabel.html('Select client…');
                customSelect.removeFilterBtn.addClass('disable');
            });
        }
        removeClient();

        function closeSelect() {
            customSelect.body.on('click', function(e) {
                if (!$(e.target).parents('.select-btn-content').length) {
                    customSelect.selectBox.removeClass('open');
                }

            });
        }
        closeSelect();

        function escClose() {
            $(document).on('keyup', function(evt) {
                //For Esc Key
                if (evt.keyCode == 27) {
                    customSelect.selectBox.removeClass('open');
                }
            });
        }
        escClose();

    },

    init: function() {
        customSelect.searchSelect();
    }

};
var example = {

	elms: {
	},

	example: function() {
	},

	init: function() {
	}
};

var filters = {
    itemFilter: $('.all-filters ul li a'),
    clearFilter: $('.remove-filter'),
    mobFilterShowBtn: $('.mobile-filter-btns .showfilter'),
    mobFilterHideBtn: $('.mobile-filter-btns .hidefilter'),
    searchField: $('#select-search'),
    filterElement: $('.client-menu-list ul li a'),


    filter: function() {
        function addremoveFilter() {
            filters.itemFilter.click(function(event) {
                event.preventDefault();
                $(this).toggleClass('selected');
                if (filters.itemFilter.hasClass('selected')) {
                    filters.clearFilter.removeClass('disable');
                }
                else{
                 filters.clearFilter.addClass('disable');
                }

            });
            filters.clearFilter.click(function(event) {
                event.preventDefault();
                filters.itemFilter.removeClass('selected');
                filters.clearFilter.addClass('disable');
            });
        }
        addremoveFilter();

        function mobileFilter() {
            filters.mobFilterShowBtn.click(function(event) {
                event.preventDefault();
                $(this).parent().parent().addClass('open-filter');
            });
            filters.mobFilterHideBtn.click(function(event) {
                event.preventDefault();
                $(this).parent().parent().removeClass('open-filter');
            });
        }
        mobileFilter();

        function searchFilter() {
            filters.searchField.on("keyup", function() {
                var value = $(this).val().toLowerCase();
                filters.filterElement.filter(function() {
                    $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                })
            });
        }
        searchFilter();
    },

    init: function() {
        filters.filter();
    }
};
var mappin = {

	countrypin: function() {

		function pinPonit() {
			$(".map-pin").each(function(index, el) {

				var content_width = $(el).children('a').outerWidth();
				var content_height = $(el).children('a').height();

				if ($(el).hasClass('bottom')) {
					$(el).children('a').css({ 'top': '1.4rem'});
			    }
			    if ($(el).hasClass('top')) {
					$(el).children('a').css({ 'top': '-3.2rem'});
			    }
			    if ($(el).hasClass('right')) {
					$(el).children('a').css({ 'left': ((content_width/2)/10+ 0.7)+'rem'});
			    }
			    if ($(el).hasClass('left')) {
					$(el).children('a').css({ 'right': ((content_width/2)/10+ 0.7)+'rem'});
			    }
			});
		}
		 pinPonit();
	},

	init: function() {
		mappin.countrypin();
	}
};

var form = {
	container: $('form'),
	selectWrapper: $('.select-wrapper'),
	select: $('select'),
	paddingInputs: {
		newsletter: $('.form-single-item'),
		smartSearchForm: $('.smart-search-form'),
	},
	typeInputs: {
		freetext: $('input[type="text"]'),
		password: $('input[type="password"]'),
		email: $('input[type="email"]'),
		textarea: $('textarea'),
	},
	closeButton: $('.inpage-form .popup-section .close-button a'),
	formCloseButton: $('.inpage-form .popup-section-2 .close-button a'),
	applynowcloseButton: $('.popup-section .close-button a'),
	popUp: $('.inpage-form .popup-section'),
	applynowpopUp: $('.popup-section'),
	formsPopUp: $('.inpage-form .popup-section-2'),
	thankyouSection: $('.inpage-form .popup-section .thankyou'),
	applynowthankyouSection: $('.popup-section .thankyou'),
	newMessageSection: $('.inpage-form .popup-section .thankyou .newmessage a'),


	ddSelect: function() {
		if (!('ontouchstart' in document.documentElement)) {
			if (form.selectWrapper.length > 0) {
				form.selectWrapper.find(form.select).dropkick();
				form.select.addClass('select-hidden').attr('tabindex', -1);
				var erroMsg = $('#Other').data('validation-empty');
				$('#Other').removeAttr('data-validation-empty');
				var lastValue = $('#Hearaboutus option:last-child').val();
				$('.dk-option').on('click', function() {

					$(this).closest(form.selectWrapper).find('span.error').addClass('valid');
					$(this).closest(form.selectWrapper).find('.dk-select-options').removeClass('select-error');
					$(this).closest(form.selectWrapper).addClass('selected-field');
				});
				$('.dk2-Hearaboutus .dk-option').on('click', function() {

					if ($(this).data('value') === lastValue) {
						$('#Other').attr('data-validation-empty', erroMsg);
						$('#Other').parent('.form-field').addClass('enabled');
					} else {
						$('#Other').removeAttr('data-validation-empty');
						$('#Other').parent('.form-field').removeClass('enabled');
						$('#Other').rules('add', {
						    required: false
						});
						$('#Other').addClass('valid');
						$('#Other-error.error').addClass('valid');
					}
					validation.init();
				});
			}
		}
	},

	keyboardAmends: function() {
		if ('ontouchstart' in document.documentElement) {
			var inputs = form.typeInputs.freetext.add(form.typeInputs.password).add(form.typeInputs.email).add(form.typeInputs.textarea);
			var mainHeader = $('.main-header');
			var hamburger = $('#navTrigger');

			inputs.focusin(function() {
				mainHeader.css('position', 'absolute').removeClass('hideh');
				hamburger.css('display', 'none');
			});

			inputs.focusout(function() {
				mainHeader.removeAttr('style');
				hamburger.removeAttr('style');
			});
		}
	},

	preventZoom: function() {
		if ('ontouchstart' in document.documentElement) {
			var searchHeader = $('#search');
			var allInputs = searchHeader;
			allInputs.css('font-size', '16px');
		}
	},

	hiddenForm: {
		wrapper: $('.with-hidden-form'),
		openEl: $('.hidden-form-open'),
		closeEl: $('.hidden-form-close'),
		contentEl: $('.hidden-form'),

		openForm: function() {
			form.hiddenForm.openEl.click(function(event){
				event.preventDefault();
				$(this).hide().closest(form.hiddenForm.wrapper).find(form.hiddenForm.contentEl).slideDown(500, function(){
						$(this).closest(form.hiddenForm.wrapper).find(form.hiddenForm.closeEl).fadeIn();
					});
				$(this).closest('form').find('input').first().focus();
			});
		},

		closeForm: function() {
			form.hiddenForm.closeEl.click(function(event){
				event.preventDefault();
				$(this).fadeOut();
				$(this).closest(form.hiddenForm.wrapper).find(form.hiddenForm.openEl).show();
				$(this).fadeOut().closest(form.hiddenForm.wrapper).find(form.hiddenForm.contentEl).slideUp();
			});
		},

		init: function() {
			form.hiddenForm.openForm();
			form.hiddenForm.closeForm();
		}
	},

	buttonRadio: {
		wrapperEl: $('.button-radio-wrapper'),
		inputEl: $('.button-radio').find('input'),
		buttonEl: $('.button-radio').find('.button'),

		highlightOnLoad: function() {
			form.buttonRadio.inputEl.each(function() {
				if ($(this).is(':checked') === true) {
					$(this).siblings('.button').addClass('inverted');
				}
			});
		},

		radioUpdate: function() {
			form.buttonRadio.buttonEl.click(function() {
				$(this).addClass('inverted');
				$(this).siblings('input').attr('checked', true);
				$(this).closest('.button-radio').siblings('.button-radio').find('input').removeAttr('checked');
				$(this).closest('.button-radio').siblings('.button-radio').find('.button').removeClass('inverted');

				if(isiPhone > -1 || isiPad > -1 || isiPod > -1) {
					form.buttonRadio.inputEl.checkboxradio('refresh');
				}
			});
		},

		init: function() {
			if (form.buttonRadio.wrapperEl.length) {
				form.buttonRadio.highlightOnLoad();
				form.buttonRadio.radioUpdate();
			}
		}
	},

	// Force to uncheck on click when it comes checked by default from umbraco
	checkedCheckbox: {
		checkbox: $('.checkbox-wrap'),

		forceUncheck: function() {
			form.checkedCheckbox.checkbox.click(function(){
				$(this).find('[checked="checked"]').removeAttr('checked').prop('checked', true);

				setTimeout(function(){
					$(this).find('[checked="checked"]').prop('checked', false);
				},500);
			});
		}
	},

	// form close button
	thankyouPopupClose: function() {
		form.closeButton.click(function(e) {
			e.preventDefault();
			form.popUp.removeClass('show');
			form.thankyouSection.removeClass('fadeInLeft');
			$('body').removeClass('fixed');
		});
	},

	// apply now form thankyou popup close
	applynowthankyouPopupClose: function() {
		form.applynowcloseButton.click(function(e) {
			e.preventDefault();
			form.applynowpopUp.removeClass('show');
			form.applynowthankyouSection.removeClass('fadeInLeft');
			$('body').removeClass('fixed');
		});
	},

	formPopupClose: function() {
		form.formCloseButton.click(function(e) {
			e.preventDefault();
			form.formsPopUp.removeClass('show');
			$('body').removeClass('fixed');
		});
	},

	newMessage: function() {
		form.newMessageSection.click(function(e) {
			e.preventDefault();
			form.popUp.removeClass('show');
			form.thankyouSection.removeClass('fadeInLeft');
			$('body').removeClass('fixed');
		});
	},

	init: function() {
		form.ddSelect();
		form.hiddenForm.init();
		form.buttonRadio.init();
		form.checkedCheckbox.forceUncheck();
		form.thankyouPopupClose();
		form.applynowthankyouPopupClose();
		form.formPopupClose();
		form.newMessage();

		if (windowWidth >= desktop) {
			form.keyboardAmends();
		}

		var erroMsg = $('#Other').data('validation-empty');
		$('#Other').removeAttr('data-validation-empty');
		var lastValue = $('#Hearaboutus option:last-child').val();
		$('#Hearaboutus').on('change', function(e) {
			if ($(e.target).val() === lastValue) {
				$('#Other').attr('data-validation-empty', erroMsg);
			} else {
				$('#Other').removeAttr('data-validation-empty');
				$('#Other').rules('add', {
				    required: false
				});
				$('#Other').addClass('valid');
				$('#Other-error.error').addClass('valid');
			}
			validation.init();
		});
	}
};
var fullpage = {
    body_selector: $("body"),
    section: $(".fp-section"),
    fullpage: $("#fullpage"),
    fullpage_mobile: $("#fullpage_mobile"),

    fullpageInit: function($el, $css3, $scrollingSpeed, $navigation, $slidesNavigation, $keyboardScrolling, $responsiveSlides, $verticalCentered, $scrollOverflow, $normalScrollElements) {
        $el.fullpage({
            licenseKey: '9EEB6EA2-F71E4211-8EA01355-1C45E6D4', // IRIS purchased key
            css3: $css3,
            scrollingSpeed: $scrollingSpeed,
            navigation: $navigation,
            slidesNavigation: $slidesNavigation,
            keyboardScrolling: $keyboardScrolling,
            responsiveSlides: $responsiveSlides,
            verticalCentered: $verticalCentered,
            scrollOverflow: $scrollOverflow,
            scrollOverflowOptions:{
                keyBindings: {
                    pageUp: 33,
                    pageDown: 34,
                }
            },
            scrollBar: false,
            normalScrollElements: '#newsletter-subscribe-form, #cstm_selector',

            afterLoad: function(origin, destination, direction) {
                $("body").attr("fp-slide", (destination.index + 1));
                if ((destination.index + 1) == 2 && window.windowWidth >= window.tabletWide) {
                    $.fn.fullpage.setMouseWheelScrolling(false);
                    $.fn.fullpage.setAllowScrolling(false);
                }
                else {
                    $.fn.fullpage.setMouseWheelScrolling(true);
                    $.fn.fullpage.setAllowScrolling(true);
                }

                // Initializing video iFrame only on First section & removing iFrame on other sections                
                if((destination.index + 1) == 1) {
                    heroVideo.init();                 
                } else {                     
                    $("#showreel").remove();                
                }                 
                // END
            }
        });
    },
    scrollDirection: function() {
        $('html.fp-enabled').on('mousewheel DOMMouseScroll', function(e) {
            if (typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
                if (e.originalEvent.detail > 0) {
                    $('.global-header').addClass('_header_hide_enable');
                } else if (e.originalEvent.detail < 0) {
                    $('.global-header').removeClass('_header_hide_enable');
                }
            } else if (typeof e.originalEvent.wheelDelta == 'number') {
                if (e.originalEvent.wheelDelta < 0) {
                    $('.global-header').addClass('_header_hide_enable');
                } else if (e.originalEvent.wheelDelta > 0) {
                    $('.global-header').removeClass('_header_hide_enable');
                }
            }
        });

        // For Mobile only
        if (fullpage.fullpage.length == 1 && window.windowWidth <= 800) {
            fullpage.fullpage.swipe({
                swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                    if (direction === 'up') {
                        $('.global-header').addClass('_header_hide_enable');
                    } else if (direction === 'down') {
                        $('.global-header').removeClass('_header_hide_enable');
                    }
                },
                threshold: 0,
                fingers: 'all'
            });
        } else if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.windowWidth == window.desktop) {
            $("body").addClass('_iPad');
            fullpage.fullpage.swipe({
                swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                    if (direction === 'up') {
                        $('.global-header').addClass('_header_hide_enable');
                        handleImageIndex("next");
                    } else if (direction === 'down') {
                        $('.global-header').removeClass('_header_hide_enable');
                        handleImageIndex("previous");
                    }
                },
                threshold: 0,
                fingers: 'all'
            });
        } else {
            fullpage.fullpage.swipe({
                swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                    if (direction === 'up') {
                        handleImageIndex("next");
                    } else if (direction === 'down') {
                        handleImageIndex("previous");
                    }
                },
                threshold: 0,
                fingers: 'all'
            });
        }
    },
    init: function() {
        if (window.windowWidth >= 800) {
            fullpage.fullpageInit(fullpage.fullpage, true, 700, false, true, true, false, true, true, null);
        } else {
            fullpage.fullpageInit(fullpage.fullpage, true, 900, false, true, true, true, false, true, '#newsletter-subscribe-form');
        }
        fullpage.scrollDirection();
    }
}
var gaUtility = {
    DATA_GA_CONVERSION_ID: 'ga-conversion-id',
    GA_EVENT_CATEGORY_CONVERSION: 'conversion',
    GA_EVENT_ACTION_CLICK: 'click',

    ga_exists: function() {
        if (window.ga) return true;
        return false;
    },

    report_conversion: function(element, url) {
        if (!gaUtility.ga_exists()) {
            // console.warn('ga does not exist');
            return;
        }

        var gaConversionID = element.data(gaUtility.DATA_GA_CONVERSION_ID);

        if (!gaConversionID) {
            // console.warn('missing data attr', gaConversionID);
            return;
        }

        ga(
            'send', 
            {
                hitType: 'event',
                eventCategory: gaUtility.GA_EVENT_CATEGORY_CONVERSION,
                eventAction: gaUtility.GA_EVENT_ACTION_CLICK,
                eventLabel: gaConversionID,
            }
        );

        return false;
    },

    init: function() {
    }
}
var getInTouchSorting = {
    countrySelector: $('.country-selector ul li a'),
    country: $('.country-selector ul li'),
    locations: $('.country-address .location'),
    showLocation: $('.location.showlocation'),
    openBtn: $('.contact-us-form'),
    regionSelector: $('.regionFilter'),
    backBtn: $('.back-btn'),
    allRegionsFilters: $('.all-regions-filters'),

    list_sorting: function() {
        var sorted_country = getInTouchSorting.country.sort(function(a, b) {
            return $(a).find('a').text().localeCompare($(b).find('a').text())
        });
        getInTouchSorting.country.remove();
        sorted_country.appendTo('.country-selector ul');
    },

    region_init: function() {
        getInTouchSorting.country.removeClass('active');
        var first_element = $('.country-selector ul').find('.enabled')[0];
        $(first_element).addClass('active');
        getInTouchSorting.show_country_box($(first_element).find('a'))
    },

    show_country_box: function(element) {
        getInTouchSorting.locations.hide();
        getInTouchSorting.locations.removeClass('showlocation');
        getInTouchSorting.locations.removeClass('fp-fadeUp');
        getInTouchSorting.thisLocation(element).css("display", "flex");;
        getInTouchSorting.thisLocation(element).addClass('showlocation');
    },

    thisLocation: function(element) {
        return $('#' + $(element).attr('id') + 'location')
    },

    sorting: function() {
        function addressFilter() {
            getInTouchSorting.openBtn.click(function() {
                getInTouchSorting.locations.removeClass('showlocation');
                getInTouchSorting.locations.removeClass('fp-fadeUp');
                getInTouchSorting.locations.hide();
                getInTouchSorting.country.addClass('enabled');
                getInTouchSorting.region_init();
            });
            $('.country-selector ul').on('click', 'li a', function(e) {
                e.preventDefault();
                getInTouchSorting.country.removeClass('active');
                $(this).parent('li').addClass('active');
                getInTouchSorting.show_country_box(this);

                if (window.windowWidth <= window.tabletWide) {
                    getInTouchSorting.allRegionsFilters.parent().addClass('openlocation');
                }

            });
        }
        addressFilter();

        function backtolocation() {
            getInTouchSorting.backBtn.click(function() {
                getInTouchSorting.allRegionsFilters.parent().removeClass('openlocation');
            });
        }
        backtolocation();

        function regionFilter() {
            getInTouchSorting.regionSelector.change(function() {
                getInTouchSorting.country.removeClass('enabled');
                getInTouchSorting.country.removeClass('disabled');
                var selected_region = $('.region-selector .dk-selected').attr('aria-activedescendant').replace('dk0-','');
                getInTouchSorting.country.filter(function(index) {
                    if ($(this).attr('data-region') == selected_region || selected_region.toUpperCase() == 'ALL') {
                        $(this).addClass('enabled');
                        return true
                    } else {
                        $(this).addClass('disabled');
                        return false
                    }
                });
                getInTouchSorting.list_sorting();
                getInTouchSorting.region_init();
            });
        }
        regionFilter();

    },

    init: function() {
        getInTouchSorting.sorting();
        getInTouchSorting.list_sorting();
        if ($(window).width() > window.tabletWide) {
            var ele=$('.countries-block')
            ele.length&&(new SimpleBar(ele[0]))
      }
    }
};
var getInTouch = {
    openBtn: $('.contact-us-form'),
    subscribeForm: $('#get-in-touch-form'),
    subscribeClose: $('.popclose'),
    mainPopup: $('.popup_wrapper'),
    mainBody: $('body'),
    mainHtml: $('html'),
    closeLayer: $('.close-layer'),

    getInTouchPopup: function() {

        function getInTouchOpen() {
            getInTouch.openBtn.click(function(e) {
                e.preventDefault();
                getInTouch.subscribeForm.show();
                getInTouch.subscribeForm.removeClass('fp-fadeOut');
                getInTouch.subscribeForm.addClass('fp-fadeIn');
                getInTouch.mainBody.addClass('getintouch-open-popup');
                getInTouch.mainHtml.addClass('getintouch-open-popup');
                gaUtility.report_conversion(getInTouch.openBtn);
            });
        }
        getInTouchOpen();

        function getInTouchModelClose(){
            getInTouch.subscribeForm.removeClass('fp-fadeIn');
            getInTouch.subscribeForm.addClass('fp-fadeOut');
            setTimeout(function() {
                getInTouch.subscribeForm.hide();
                getInTouchSorting.allRegionsFilters.parent().removeClass('openlocation');
            }, 600);
            getInTouch.mainBody.removeClass('getintouch-open-popup');
            getInTouch.mainHtml.removeClass('getintouch-open-popup');
        }

        function getInTouchOpenClose() {
            getInTouch.subscribeClose.click(function() {
                getInTouchModelClose();
            });
        }
        getInTouchOpenClose();

        function closegetInTouchOpen() {
            getInTouch.closeLayer.click(function(e) {
                getInTouchModelClose();
            });
        }
        closegetInTouchOpen();

        function escClosegetInTouch() {
            $(document).on('keyup', function(evt) {
                if (evt.keyCode == 27) {
                    getInTouchModelClose();
                }
            });
        }
        escClosegetInTouch();

    },

    init: function() {
        getInTouch.getInTouchPopup();
    }
}
var heroVideo = {
    videoIdElement: $('#herovimeovideo'),
    videoPlaceholder: $('#herovimeovideoplaceholder'),
    videoPlaceholderContent: $('#herovimeovideoplaceholder > div'),
    videoController: $('.video-controller'),

    videoBlock: function() {

        function vimeoVideoPlaceholderShow() {
            TweenLite.to(
                heroVideo.videoPlaceholderContent,
                0.5, {
                    alpha: 1,
                    ease: Strong.easeInOut,
                    onStart: function() {
                        heroVideo.videoPlaceholderContent.css('visibility', 'visible');
                    }
                }
            );
        }

        function vimeoVideoPlaceholderHide() {
            TweenLite.to(
                heroVideo.videoPlaceholder,
                0.5, {
                    alpha: 0,
                    ease: Strong.easeInOut,
                    onComplete: function() {
                        heroVideo.videoPlaceholder.css('visibility', 'hidden');
                    }
                }
            );
        }

        function vimeoVideoPlaceholderShow() {
            TweenLite.to(
                heroVideo.videoPlaceholderContent,
                0.5, 
                { 
                    alpha: 1, 
                    ease: Strong.easeInOut,
                    onStart: function() {
                        heroVideo.videoPlaceholderContent.css('visibility', 'visible');
                    }
                }
            );
        }

        function vimeoVideoPlaceholderHide() {
            TweenLite.to(
                heroVideo.videoPlaceholder, 
                0.5,
                { 
                    alpha: 0, 
                    ease: Strong.easeInOut,
                    onComplete: function() {
                        heroVideo.videoPlaceholder.css('visibility', 'hidden');
                    }
                }
            );
        }

        function vimeoBackgroundVideo() {

            // Embed video

            var vimeoId = heroVideo.videoIdElement.attr('data-vimeo-id-hero');
            var videoElement =
                '<iframe class="fullscreen-video" id="showreel" ' +
                'src="https://player.vimeo.com/video/' + vimeoId + '?dnt=1&autoplay=1&muted=1&color=black&api=1&player_id=showreel&portrait=0&background=1&loop=1&byline=0&title=0&sidedock=0&controls=0" ' +
                'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>';

            heroVideo.videoIdElement.append(videoElement);

            // Setup video placeholder
            // Allow video a chance to start playing before showing placeholder

            var videoPlaceholderTimeoutID = null;

            videoPlaceholderTimeoutID = setTimeout(
                function() {
                    vimeoVideoPlaceholderShow();
                },
                2000
            );

            // Check video status
            // If playing, hide placeholder
            /* 
            
            !!! Very Important !!!
            
            The Vimeo Player API has been embedded directly in markup at that exact position to prevent existing 
            elements with a data-vimeo-id attribute from autoloading an iframe and potentially breaking the site.
            
            This is the least worst solution without breaking existing functionality.
            
            See https://developer.vimeo.com/player/sdk/embed for why data-vimeo-id triggers an autoload.
            
            If you have time fix this by renaming all data-vimeo-id attributes to be something else.
            
            */

            var videoIFrame = $(heroVideo.videoIdElement).find('iframe')[0];
            var videoPlayer = new Vimeo.Player(videoIFrame);

            videoPlayer.on(
                'play',
                function() {
                    clearTimeout(videoPlaceholderTimeoutID);
                    vimeoVideoPlaceholderHide();
                }
            );

        }

        vimeoBackgroundVideo();
    },

    init: function() {
        heroVideo.videoBlock();
    }
};
function ie_image_fix(images) {
    images.each(function(ind, val) {
        var imageSource = $(this).children("img").attr('src');
        $(this).children("img").css({'display': 'none'});
        $(this).css({
            'backgroundSize': 'cover',
            'backgroundImage': 'url(' + imageSource + ')',
            'backgroundPosition': 'top center'
        })
    });
}

if ('objectFit' in document.documentElement.style === false || navigator.appVersion.indexOf("Edge") != -1) {
    ie_image_fix($('.ie-image-fix'))
} else {}
var joblisting = {
	filters_bar: $('select.job-locations, select.job-department'),
	jobLocation: $('select.job-locations'),
	jobDepartment: $('select.job-department'),
	job: $('.i_w_037_careers_details .i_w_cre_wrk_card'),


	locationSorting: function(){
		var location_selected = joblisting.jobLocation.val();
		var department_selected = joblisting.jobDepartment.val();
  		joblisting.job.show();
		var final_preview=joblisting.job.filter(function(index) {
			let ele_region=$(this).attr('data-region');
			let ele_dept=$(this).attr('data-department');
			$(this).removeClass('filter_true');
			if((location_selected=='all'|| ele_region==location_selected)
				&&(department_selected=='all'|| ele_dept==department_selected))
			{
				
				return false;
			}
			$(this).addClass('filter_true');
			return true
		});
		// final_preview.addClass('hidden');
		// console.log(final_preview);
		final_preview.hide();
	},


	joblistingSorting: function() {

		function locationFilert(){
			joblisting.filters_bar.on('change', function() {
				joblisting.locationSorting();
				pagination.paginationBlock();
			});
		}
		locationFilert();
	},

	init: function(){
		joblisting.joblistingSorting();
		pagination.paginationBlock();
	},

	initBamboo: function() {
		// Reselect elements as bamboo jobs data is frontend driven
		this.filters_bar = $('select.job-locations, select.job-department');
		this.jobLocation = $('select.job-locations');
		this.jobDepartment =$('select.job-department');
		this.job = $('.i_w_037_careers_details .i_w_cre_wrk_card');
		this.init();
	}
};
var jobsBamboo = {

    // REFERENCES //

    // Bamboo selectors should be colocated in convertBambooJobEmbedToData()

    SEL_DATA_JOB_FEATURE_LINK_1: 'data-job-feature-link-1',
    SEL_DATA_JOB_FEATURE_LINK_2: 'data-job-feature-link-2',
    SEL_DATA_JOB_FEATURE_LINK_3: 'data-job-feature-link-3',
    SEL_TEMPLATE_FILTER_DEPARTMENT: '#careers__filter-department-template',
    SEL_TEMPLATE_FILTER_LOCATION: '#careers__filter-location-template',
    SEL_TEMPLATE_JOB: '#careers__job-template',
    SEL_FILTER_DEPARTMENT_CONTAINER: '#careers__filter_department-container',
    SEL_FILTER_LOCATION_CONTAINER: '#careers__filter_location-container',
    SEL_JOBS_CONTAINER: '#careers__jobs_container',
    SEL_PAGE_CAREERS: '.page-Careers',
    SEL_PAGE_CAREERS_DETAILS: '.page-careers_details',

    WS_BAMBOO_JOB_EMBED: 'https://irisworldwide.bamboohr.com/jobs/embed2.php?departmentId=0',

    JOBS_PREVIEW_JOBS_MAX: 3,

    // PROPERTIES //

    /* dataBamboo interface
    {
        id(department): {
            id
            name
            jobs: {
                id(job): {
                    id
                    title
                    locationID
                    location
                    place
                    locality
                    link
                }
            }
        }
    }
    */
    dataBamboo: null,

    /* dataDepartments interface
    {
       id: {
           id
           name
       }
    }
    */
    dataDepartments: null,

    /* dataLocations interface
    {
        id: {
            id
            location
            place
            locality
        }
    }
    */
    dataLocations: null,

    // UTILITY //

    isPageCareers: function() {
        return $(this.SEL_PAGE_CAREERS).length !== 0;
    },

    isPageCareersDetails: function() {
        return $(this.SEL_PAGE_CAREERS_DETAILS).length !== 0;
    },

    processHTMLText: function(html) {
        // Parse html entities
        return $.trim(
            $('<div />').html(html)
            .text()
        );
    },

    processRawTemplate: function(templateRaw) {
        // Convert to actual handlebars
        // Uses [[ and ]] instead due to frontend already being a template file
        return templateRaw
            .replace(/\[\[/g, '{{')
            .replace(/\]\]/g, '}}');
    },

    convertLinkToDataBambooLink: function(linkAny) {
        var linkProcess = linkAny
            .replace(/http:\/\//g, '') // strip http://
            .replace(/https:\/\//g, '') // strip https://
            .replace(/\/\//g, ''); // strip //
        if (linkProcess.substr(0, 1) === '/') linkProcess = linkAny.substr(1); // strip /
        // strip all params except id
        var paramID = '';
        var pieces = linkProcess.split('?');
        var path = pieces[0];
        var query = pieces[1];
        var queryPieces = query.split('&');
        $.each(
            queryPieces,
            function(index, queryPiece) {
                var queryPiecePieces = queryPiece.split('=');
                if (queryPiecePieces[0] === 'id') paramID = queryPiecePieces[1];
            }
        );
        return '//'+path+'?'+'id='+paramID; // prefix // to match bamboo
    },

    arrayRemoveEmpty: function(arrayInitial) {
        var arrayFinal = [];
        $.each(
            arrayInitial,
            function(index, arrayItem) {
                if (typeof arrayItem !== 'undefined' && arrayItem !== null) {
                    arrayFinal.push(arrayItem);
                }
            }
        );
        return arrayFinal;
    },

    // DATA //

    wsBambooGetEmbed: function() {
        $.ajax({
            url: this.WS_BAMBOO_JOB_EMBED,
            error: function(err) {
                this.onDataSuccess(null);
            }.bind(this),
            success: function(res) {
                this.onDataSuccess(res);
            }.bind(this)
        });
    },

    convertBambooJobEmbedToData: function(dataEmbed) {

        this.dataBamboo = {};
        this.dataDepartments = {};
        this.dataLocations = {};

        var parsedHTML = $.parseHTML(dataEmbed);

        if (parsedHTML === null) {
            // console.warn('bad request');
            return;
        }

        var elmMain = $(parsedHTML[0]);
        var elmDepartments = elmMain.find('.BambooHR-ATS-Department-Item');

        if (elmDepartments.length === 0) {
            // console.warn('data corrupt or no jobs');
            return;
        }

        //

        this.dataBamboo = {};
        this.dataDepartments = {};
        this.dataLocations = {};

        $.each(
            elmDepartments,
            function(index, elmDepartmentsItem) {

                var elmDepartment = $(elmDepartmentsItem);
                var elmDepartmentName = elmDepartment.find('.BambooHR-ATS-Department-Header');
                var elmDepartmentJobs = elmDepartment.find('.BambooHR-ATS-Jobs-Item');

                var departmentID = elmDepartmentName.attr('id');
                var departmentName = this.processHTMLText(elmDepartmentName.html());

                this.dataBamboo[departmentID] = {};
                var dataDepartment = this.dataBamboo[departmentID];
                dataDepartment.id = departmentID;
                dataDepartment.name = departmentName;
                dataDepartment.jobs = {};

                this.dataDepartments[departmentID] = {
                    id: departmentID,
                    name: departmentName
                };

                $.each(
                    elmDepartmentJobs,
                    function(index, elmDepartmentJobsItem) {

                        var elmDepartmentJob = $(elmDepartmentJobsItem);

                        var departmentJobID = elmDepartmentJob.attr('id');
                        var departmentJobLink = elmDepartmentJob.find('a').attr('href');
                        var departmentJobTitle = this.processHTMLText(elmDepartmentJob.find('a').html());
                        var departmentJobLocation = this.processHTMLText(elmDepartmentJob.find('span').html());

                        // Assume before first comma is place
                        // Format: [place], [locality]
                        // Examples:
                        // New York City, New York
                        // London, London, City of
                        var departmentJobLocationPieces = departmentJobLocation.split(', ');
                        var departmentJobPlace = departmentJobLocationPieces[0];
                        departmentJobLocationPieces.shift();
                        var departmentJobLocality = departmentJobLocationPieces.join(', ');

                        // locationID conversion:
                        // "New York City, New York" > "new-york-city-new-york"
                        // "London, London, City of" > "london-london-city-of"
                        var departmentJobLocationID = departmentJobLocation.toLowerCase()
                            .replace(/,\s/g, '-')
                            .replace(/\s/g, '-');

                        dataDepartment.jobs[departmentJobID] = {
                            id: departmentJobID,
                            title: departmentJobTitle,
                            locationID: departmentJobLocationID,
                            location: departmentJobLocation,
                            place: departmentJobPlace,
                            locality: departmentJobLocality,
                            link: departmentJobLink,
                        };

                        this.dataLocations[departmentJobLocationID] = {
                            id: departmentJobLocationID,
                            location: departmentJobLocation,
                            place: departmentJobPlace,
                            locality: departmentJobLocality
                        };

                    }.bind(this)
                );

            }.bind(this)
        );

        // console.log(this.dataBamboo);
        // console.log(this.dataDepartments);
        // console.log(this.dataLocations);

    },

    // RENDER //

    renderFilters: function() {

        // Department Filters

        var rawFilterDepartment = $(this.SEL_TEMPLATE_FILTER_DEPARTMENT).html();
        rawFilterDepartment = this.processRawTemplate(rawFilterDepartment);

        var tmplFilterDepartment = Handlebars.compile(rawFilterDepartment);

        var dataDepartmentsCopy = $.extend(true, {}, this.dataDepartments);
        var dataDepartmentsByName = [];
        var dataDepartmentsSorted = [];

        $.each(
            Object.keys(dataDepartmentsCopy),
            function(index, departmentID) {
                var dataDepartment = dataDepartmentsCopy[departmentID];
                dataDepartmentsByName[dataDepartment.name] = dataDepartment;
            }
        );

        $.each(
            Object.keys(dataDepartmentsByName).sort(),
            function(index, departmentName) {
                dataDepartmentsSorted.push(dataDepartmentsByName[departmentName]);
            }
        );

        var htmlFilterDepartment = tmplFilterDepartment({ departments: dataDepartmentsSorted });
        $(this.SEL_FILTER_DEPARTMENT_CONTAINER).html(htmlFilterDepartment);

        // Location Filters

        var rawFilterLocation = $(this.SEL_TEMPLATE_FILTER_LOCATION).html();
        rawFilterLocation = this.processRawTemplate(rawFilterLocation);

        var tmplFilterLocation = Handlebars.compile(rawFilterLocation);

        var dataLocationsCopy = $.extend(true, {}, this.dataLocations);
        var dataLocationsSorted = [];

        $.each(
            Object.keys(dataLocationsCopy).sort(),
            function(index, locationID) {
                dataLocationsSorted.push(dataLocationsCopy[locationID]);
            }
        );

        var htmlFilterLocation = tmplFilterLocation({ locations: dataLocationsSorted });
        $(this.SEL_FILTER_LOCATION_CONTAINER).html(htmlFilterLocation);

    },

    renderJobsPreview: function() {

        var dataJobs = [];

        $.each(
            this.dataBamboo,
            function(index, dataDepartment) {
                $.each(
                    dataDepartment.jobs,
                    function(index, dataJob) {
                        dataJobs.push({
                            locationID: dataJob.locationID,
                            locationPlace: dataJob.place,
                            departmentID: dataDepartment.id,
                            departmentName: dataDepartment.name,
                            jobLink: dataJob.link,
                            jobTitle: dataJob.title
                        });
                    }
                );
            }
        );

        var elmJobsContainer = $(this.SEL_JOBS_CONTAINER);

        /* 
        // Randomise

        var dataJobsRandom = [];
        var i = 0;
        while (i < this.JOBS_PREVIEW_JOBS_MAX && dataJobs.length > 0) {
            var dataJobIndexRandom = Math.floor(Math.random() * dataJobs.length);
            var dataJobRandom = dataJobs.splice(dataJobIndexRandom, 1).pop();
            dataJobsRandom.push(dataJobRandom);
            i++;
        }
        */

        // Featured

        var dataJobsFeaturedLinks = [];
        var dataJobsFeatured = [];
        var dataJobFeatureLink1 = elmJobsContainer.attr(this.SEL_DATA_JOB_FEATURE_LINK_1);
        var dataJobFeatureLink2 = elmJobsContainer.attr(this.SEL_DATA_JOB_FEATURE_LINK_2);
        var dataJobFeatureLink3 = elmJobsContainer.attr(this.SEL_DATA_JOB_FEATURE_LINK_3);

        if (dataJobFeatureLink1) dataJobsFeaturedLinks.push(this.convertLinkToDataBambooLink(dataJobFeatureLink1));
        if (dataJobFeatureLink2) dataJobsFeaturedLinks.push(this.convertLinkToDataBambooLink(dataJobFeatureLink2));
        if (dataJobFeatureLink3) dataJobsFeaturedLinks.push(this.convertLinkToDataBambooLink(dataJobFeatureLink3));

        $.each(
            dataJobs,
            function(index, dataJob) {
                var foundIndex = dataJobsFeaturedLinks.indexOf(dataJob.jobLink);
                if (foundIndex > -1) {
                    dataJobsFeatured[foundIndex] = dataJob;
                }
            }
        );
        dataJobsFeatured = this.arrayRemoveEmpty(dataJobsFeatured);

        //

        var rawJob = $(this.SEL_TEMPLATE_JOB).html();
        rawJob = this.processRawTemplate(rawJob);
        var tmplJob = Handlebars.compile(rawJob);

        // var dataJobsFinal = dataJobs;
        // var dataJobsFinal = dataJobsRandom;
        var dataJobsFinal = dataJobsFeatured;

        $.each(
            dataJobsFinal,
            function(index, dataJob) {
                var htmlJob = tmplJob(dataJob);
                elmJobsContainer.append(htmlJob);
            }
        );

    },

    renderJobs: function() {

        var dataJobs = [];

        $.each(
            this.dataBamboo,
            function(index, dataDepartment) {
                $.each(
                    dataDepartment.jobs,
                    function(index, dataJob) {
                        dataJobs.push({
                            locationID: dataJob.locationID,
                            locationPlace: dataJob.place,
                            departmentID: dataDepartment.id,
                            departmentName: dataDepartment.name,
                            jobLink: dataJob.link,
                            jobTitle: dataJob.title
                        });
                    }
                );
            }
        );

        var rawJob = $(this.SEL_TEMPLATE_JOB).html();
        rawJob = this.processRawTemplate(rawJob);
        var tmplJob = Handlebars.compile(rawJob);

        var elmJobsContainer = $(this.SEL_JOBS_CONTAINER);

        $.each(
            dataJobs,
            function(index, dataJob) {
                var htmlJob = tmplJob(dataJob);
                elmJobsContainer.append(htmlJob);
            }
        );

    },

    // INIT //

    init: function() {
        if (!this.isPageCareers() && !this.isPageCareersDetails()) return;
        this.wsBambooGetEmbed();
    },

    onDataSuccess: function(dataEmbed) {
        if (this.isPageCareers()) this.onDataSuccessCareers(dataEmbed);
        if (this.isPageCareersDetails()) this.onDataSuccessCareersDetails(dataEmbed);
    },

    onDataSuccessCareers: function(dataEmbed) {
        this.convertBambooJobEmbedToData(dataEmbed);
        this.renderJobsPreview();
    },

    onDataSuccessCareersDetails: function(dataEmbed) {
        this.convertBambooJobEmbedToData(dataEmbed);
        this.renderFilters();
        this.renderJobs();
        joblisting.initBamboo();
        selects.init();
    }

}
var googlemapBlock = {
    mapBlock: $('.google-map-block'),
    viewLarge: $('.see-large a'),
    mapApiKey: 'AIzaSyDWHW__BWwftgWlQC3ebTwVYNjiGzmt7ts', //purchased key

    mapApi: function() {

        function mapInitilization() {
            googlemapBlock.mapBlock.lazyLoadGoogleMaps({
                key: googlemapBlock.mapApiKey,
                callback: function(container, map) {
                    var locationAddress = $(container).attr('data-location');
                    var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(locationAddress) + '&sensor=false&key=' + googlemapBlock.mapApiKey;
                    var seelargeLink = 'https://www.google.com/maps/search/' + encodeURIComponent(locationAddress);

                    $.get(url, function(data) {
                        if (data.status == 'OK') {
                            var location = data.results[0].geometry.location;

                        }
                        var center = new google.maps.LatLng(location.lat, location.lng);
                        map.setOptions({
                            zoom: 15,
                            center: center,
                            disableDefaultUI: true,
                            styles: [{
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#f5f5f5"
                                    }]
                                },
                                {
                                    "elementType": "labels.icon",
                                    "stylers": [{
                                        "visibility": "off"
                                    }]
                                },
                                {
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#616161"
                                    }]
                                },
                                {
                                    "elementType": "labels.text.stroke",
                                    "stylers": [{
                                        "color": "#f5f5f5"
                                    }]
                                },
                                {
                                    "featureType": "administrative.land_parcel",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#bdbdbd"
                                    }]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#eeeeee"
                                    }]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#757575"
                                    }]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#e5e5e5"
                                    }]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#9e9e9e"
                                    }]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#ffffff"
                                    }]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#757575"
                                    }]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#dadada"
                                    }]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#616161"
                                    }]
                                },
                                {
                                    "featureType": "road.local",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#9e9e9e"
                                    }]
                                },
                                {
                                    "featureType": "transit.line",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#e5e5e5"
                                    }]
                                },
                                {
                                    "featureType": "transit.station",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#eeeeee"
                                    }]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "geometry",
                                    "stylers": [{
                                        "color": "#c9c9c9"
                                    }]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "labels.text.fill",
                                    "stylers": [{
                                        "color": "#9e9e9e"
                                    }]
                                }
                            ]
                        });
                        var icon = {
                            url: "/img/locationpin.svg", // url
                            scaledSize: new google.maps.Size(36, 59)
                        };
                        new google.maps.Marker({
                            position: center,
                            map: map,
                            icon: icon
                        });
                    });

                    $('.addressmapblock .see-large a').attr("href", seelargeLink);

                }
            });
        }
        mapInitilization();
    },
    init: function() {
        googlemapBlock.mapApi();
    }
};
var menu = {
    header: $('.global-header'),
    desktopLogo: $('.desktop-logo'),
    mobileLogo: $('.mobile-logo'),
    mobileIcon: $('.hamburger-icon'),
    mobileIcon: $('.hamburger-icon'),
    mainNav: $('.main-nav'),
    mainSection: $('#mainContent'),
    navLink: $('nav ul.navigation li a'),
    mainBody: $('body, html'),
    fullPage: $("#fullpage"),

    navigation: function() {
        function mobileMenu() {
            var wh = $(window).height() - 90;
            menu.mobileIcon.click(function() {
                menu.mobileIcon.toggleClass('active');
                menu.mainNav.toggleClass('open');
                menu.header.toggleClass('open-mobile-menu');
                menu.mainSection.toggleClass('slidebody');
                menu.mainBody.toggleClass('overflow-hidden');
                menu.header.toggleClass('animationcomplete');
                menu.mainNav.scrollTop(0);
                if (menu.fullPage.length == 1 && menu.header.hasClass('open-mobile-menu')) {
                    $("#cstm_selector").height(wh);
                    menu.mainNav.niceScroll();
                }

            });
        }
        mobileMenu();

        function menuBehaviour() {
            var didScroll;
            var lastScrollTop = 0;
            var delta = 5;
            var navbarHeight = $('.global-header').outerHeight();

            $(window).scroll(function(event) {
                didScroll = true;
            });

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 100);

            function hasScrolled() {
                var st = $(window).scrollTop();
                if (Math.abs(lastScrollTop - st) <= delta)
                    return;
                if (st > lastScrollTop && st > navbarHeight + 40) {
                    // Scroll Down
                    $('.global-header').removeClass('_header_hide_disable').addClass('_header_hide_enable');
                } else {
                    // Scroll Up
                    if (st + $(window).height() < $(document).height()) {
                        $('header').removeClass('_header_hide_enable').addClass('_header_hide_disable');
                    }
                }

                lastScrollTop = st;
            }
        }
        menuBehaviour();


    },

    init: function() {
        menu.navigation();
    }

}
var pagination = {

	paginationBlock: function() {
		function mainPagination() {
			$('.paginable').show();
			$('.filter_true').hide();
			var items = $('.i_w_037_careers_details .i_w_cre_wrk_card:visible');
			items.each(function(index, el) {
				$(this).addClass('paginable');
			});
		    var numItems = items.length;
		    var perPage = 6;
		    // $('.i_w_037_careers_details .i_w_cre_wrk_card')
		    var total_pages = numItems/perPage;
			var ulcontainer = $('body').find("#custom_paginator");
			ulcontainer.find('li').remove();
			if(!ulcontainer.length)
			{ ulcontainer = $('<ul id="custom_paginator" data-activepage="0"></ul>')}
			var active_page = parseInt(ulcontainer.attr("data-activepage"));
			for(var i=0;i<total_pages;i++)
			{	var li_page = $('<li><a href="javascript:void(0);">'+(i+1)+'</a></li>');
				if(active_page==i)
				{
					li_page.find('a').addClass('active');
				}
				ulcontainer.append(li_page);
			}
			$('body').find('.pagingnation-list').append(ulcontainer);


			items.hide();
		    items.slice(active_page*perPage,perPage*(active_page+1)).show();
		} 
		mainPagination();

		function pageNumber(options) {
			if(!options)
			{
				return false;
			}
			// console.log(options);
			
		}

		$('.pagingnation-list').on('click','a',function(){
			if($(this).hasClass('active')){ return false;}
			$('.pagingnation-list').find('.active').removeClass('active');
			$(this).addClass('active');
			var parentUl = $(this).parents('ul');
			var indexx = parseInt($(this).text());
			// var currentIndex = parseInt(parentUl.attr('data-activepage'));
			parentUl.attr('data-activepage',indexx-1);
			mainPagination();

		});
	}
}
var newsletter = {
    openBtn: $('.subscribe-newsletter-btn'),
    subscribeForm: $('#newsletter-subscribe-form'),
    onlyForm: $('#signupform'),
    subscribeClose: $('.popclose'),
    mainPopup: $('.popup_wrapper'),
    submitButton: $('#submitbtn'),
    formMsg: $('.thank-msg'),
    mainBody: $('body'),
    mainHtml: $('html'),

    popup: function() {

        function popupModelOpen() {
            $('body').on('click','.subscribe-newsletter-btn',function(e) {
                e.preventDefault();
                $('body').removeClass('subs-thanksmsg');
                newsletter.subscribeForm.show();
                newsletter.subscribeForm.removeClass('fp-fadeOut');
                newsletter.subscribeForm.addClass('fp-fadeIn');
                newsletter.onlyForm.show();
                newsletter.mainBody.addClass('newsletter-subscribe-open-popup');
                newsletter.mainHtml.addClass('newsletter-subscribe-open-popup');
                newsletter.onlyForm.siblings(".news-subscriber.thank-msg").hide();
                newsletter.onlyForm[0].reset();
                $('.inputField').parent().removeClass('highlight');
                $('span.error').remove();
                if (window.windowWidth <= 799) {
                    $(".i_w_008_pop-up").niceScroll();
                }
            });
        }
        popupModelOpen();


        function popupModelClose() {
            newsletter.subscribeForm.removeClass('fp-fadeIn');
            newsletter.subscribeForm.addClass('fp-fadeOut');
            setTimeout(function() {
                newsletter.subscribeForm.hide();
                $('body').removeClass('subs-thanksmsg');
            }, 600);
            newsletter.mainBody.removeClass('newsletter-subscribe-open-popup');
            newsletter.mainHtml.removeClass('newsletter-subscribe-open-popup');
        }
        popupModelClose();

        function popupModelCloseBtn() {
            $('body').on('click','.popclose',function(e) {
                popupModelClose();
                $(".i_w_008_pop-up").niceScroll().remove();
                if ($('#fullpage').length == 1) {
                    if (window.windowWidth <= 799) {
                        document.location.reload(true);
                    }
                }
                
            });
        }
        popupModelCloseBtn();

        function closePopup() {
            $('body').on('click','.close-layer',function(e){
                    popupModelClose();
                    $(".i_w_008_pop-up").niceScroll().remove();
                    if ($('#fullpage').length == 1) {
                        if (window.windowWidth <= 799) {
                            document.location.reload(true);
                        }
                    }
                    
                    
            });
        }
        closePopup();

        function escClose() {
            $(document).on('keyup', function(evt) {
                if (evt.keyCode == 27) {
                    popupModelClose();
                    $(".i_w_008_pop-up").niceScroll().remove();
                    if ($('#fullpage').length == 1) {
                        if (window.windowWidth <= 799) {
                            document.location.reload(true);
                        }
                    }
                     
                    
                }
            });
        }
        escClose();


        function formClass() {

            function highlight_input_field(field) {
                if ($(field).val().length > 0) {
                    $(field).parent().addClass('highlight');
                } else {
                    $(field).parent().removeClass('highlight');
                }
            }

            $('.inputField').on('keyup', function() {
                $('.inputField').each(function(index, value) {
                    highlight_input_field(this);
                });
            });

            $('.inputField').on('change', function() {
                highlight_input_field(this);
            });
        }

        formClass();

    },

    init: function() {
        newsletter.popup();
    }

}
var selects = {

    $selectWrap: null,

    getEachSelect: function() {
        selects.$selectWrap.find('select').each(function() {
            if ('ontouchstart' in document.documentElement) {
                selects.mobileSelects($(this));
            } else {
                selects.desktopSelects($(this));
            }
        });
    },

    desktopSelects: function($select) {
        var select = $select.dropkick();
    },

    mobileSelects: function($select) {
        var selectText = $select.find('option').first().text();
        var $fakeSelect = $('<div>').addClass('mobile-select').text(selectText);
        var $selectWrap = $select.closest('.select-wrapper');

        $selectWrap.prepend($fakeSelect);

        $select.on('change', function(e) {
            // $selectWrap.find('.mobile-select').text($(e.target).val());
            $selectWrap.find('.mobile-select').text($select.find('option:selected').text());
        });
    },

    init: function() {
        selects.$selectWrap = $(document).find('.select-wrapper');

        if (selects.$selectWrap.length)
            selects.getEachSelect();
    }
};
var slider = {
    keyPeopleSlider: $('#keypeople_mobile_slider'),
    workPgSlider: $('.boxslider'),
    workPopupSlider: $('.modal-boxslider'),
    sliderSlide: $('.boxslider .slider-items'),
    sliderDots: $('.boxslider .slick-dots'),


    sliderOpen: function() {
        if (window.isifirefox) {
            $('.page-work-details').addClass('popup_thm');
        }
        if ($(window).width() < window.tabletWide) {
            $('.modal_popup_box').addClass('mob_box');
        }

        $(document).on('click', '.slick-slide', function(e) {
            $('.modal_popup_box').addClass('modal_section_opoen');
            $('body').addClass('popupslideropen')
            var imageindex = parseInt($(this).attr('data-slick-index'));
            $('.modal-boxslider').slick('slickGoTo', imageindex);
            $('.modal-boxslider').slick('setPosition');

            var slides = slider.workPopupSlider.find('.slick-slide');
            slides.each(function() {
                let slide_id = $(this).attr('id');
                let slidenumber = parseInt($(this).attr('data-slick-index'));
                slidenumber < 10 && (slidenumber = '0' + slidenumber)
                let slide_src = $(this).find('img').attr('src');
                var buttpon = $('.modal-boxslider').find('[aria-controls="slick-slide' + slidenumber + '"]')
                buttpon.css('background-image', 'url(' + slide_src + ')')

            })
        });
    },

    sliderClose: function() {
        $(document).on('click', '.close-btn', function(e) {
            $('.modal_popup_box').removeClass('modal_section_opoen');
            $('body').removeClass('popupslideropen')
        });
    },
    sliderWidth: function() {
        slider.workPgSlider.on('init', function() {
            var fullslideWidth = slider.sliderSlide.width();
            slider.workPgSlider.find('.slick-dots').css('width', fullslideWidth);
            if ($(window).width() <= window.tabletWide) {
                slider.workPgSlider.find('button.slick-next').css({
                    'left': 'calc(' + fullslideWidth + 'px - 14px'
                });
            } else {
                slider.workPgSlider.find('button.slick-next').css({
                    'left': 'calc(' + fullslideWidth + 'px - 17px'
                });
            }
        });
    },

    sliderFunction: function(
        $el,
        $dots,
        $infinite,
        $slidesToShow,
        $slidesToScroll,
        $variableWidth,
        $speed,
        $autoplay,
        $centerPadding,
        $arrows,
        $mobileFirst,
        $asNavFor,
        $focusOnSelect,
        $initialSlide,
    ) {
        $el.slick({
            dots: $dots,
            infinite: $infinite,
            slidesToShow: $slidesToShow,
            slidesToScroll: $slidesToScroll,
            variableWidth: $variableWidth,
            speed: $speed,
            autoplay: $autoplay,
            centerPadding: $centerPadding,
            arrows: $arrows,
            mobileFirst: $mobileFirst,
            asNavFor: $asNavFor,
            focusOnSelect: $focusOnSelect,
            initialSlide: $initialSlide,
        });
    },

    init: function() {
        slider.sliderClose();
        slider.sliderOpen();
        slider.sliderWidth();
        $(window).resize(function() {
            slider.sliderWidth();
        });

        if ($(window).width() < window.tab) {
            slider.sliderFunction(
                slider.keyPeopleSlider,
                true,
                true,
                1,
                1,
                false,
                300,
                false,
                0,
                false,
                false,
                null,
                false,
                0
            );
        }
        if ($(window).width() > window.tabletWide) {
            slider.sliderFunction(
                slider.workPopupSlider,
                true,
                false,
                1,
                1,
                false,
                300,
                false,
                0,
                false,
                false,
                null,
                false,
                0
            );
        }
        slider.sliderFunction(
            slider.workPgSlider,
            true,
            true,
            1.058,
            1,
            false,
            300,
            false,
            0,
            true,
            false,
            null,
            false,
            0
        );
    }

};
var validation = {

    setMethods: function() {
        // Define error message options (format and position relative to error input)
        $.validator.setDefaults({
            debug: true,
            success: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                if ($("#hasForm").length > 0) {
                    if ($(element).is(':checkbox')) { // Move message on checkboxes
                        // error.insertAfter( $(element).parent() );
                        $(element).parent().append(error);
                    } else if ($(element).is(':radio')) { // Move message on radio buttons
                        $(element).parent().parent().append(error);
                    } else if ($(element).attr('data-validation-nomessage')) { // Remove validation message
                        error.remove();
                    } else { // This is the default behavior of the script
                        $(element).parent().append(error);
                    }
                } else {
                    if ($(element).is(':checkbox')) { // Move message on checkboxes
                        error.insertAfter($(element).parent());
                    } else if ($(element).is(':radio')) { // Move message on radio buttons
                        error.insertAfter($(element).parent().parent());
                    } else if ($(element).attr('data-validation-nomessage')) { // Remove validation message
                        error.remove();
                    } else if ($(element).is('select')) { // select dropdown
                        error.insertAfter($(element));
                    } else { // This is the default behavior of the script
                        error.insertAfter($(element).siblings('label'));
                    }
                }
            }
        });

        // Validation method for regex expressions
        $.validator.addMethod(
            'regexFormat',
            function(value, element) {
                var regexString = $(element).attr('data-validation-regex');
                var check = false;
                var re = new RegExp(regexString);
                return this.optional(element) || (re.test(value) > 0);
            }
        );

        // Validation method for inputs that need matching other inputs
        $.validator.addMethod(
            'matchInput',
            function(value, element) {
                var $this = $(element);
                var $thisVal = $this.val();
                var $matchEl = $this.attr('data-validation-matchTo');
                var $matchElVal = $this.closest('form').find('[name="' + $matchEl + '"]').val();

                if ($thisVal !== $matchElVal) {
                    return false;
                } else {
                    return true;
                }
            }
        );

        // Validation method for custom dropdowns
        $.validator.addMethod(
            'selectRequired',
            function(value, element) {
                // console.log('single', $(element));
                if (value === 'clearSelect' || value === '') {
                    $(element).closest('.select-wrapper').find('.dk-selected').addClass('select-error');
                    return false;
                } else {
                    $(element).closest('.select-wrapper').find('.dk-selected').removeClass('select-error');
                    return true;
                }
            }
        );

        // Validation method for custom multiselection dropdowns
        $.validator.addMethod(
            'multiselectRequired',
            function(value, element) {
                console.log('multi', $(element));
                if (value) {
                    $(element).closest('.select-wrapper').find('.dk-select-multi .dk-select-options').removeClass('select-error');
                    return true;
                } else {
                    $(element).closest('.select-wrapper').find('.dk-select-multi .dk-select-options').addClass('select-error');
                    return false;
                }
            }
        );

        // Validation method for min length
        $.validator.addMethod(
            'minLengthCheck',
            function(value, element) {
                var $lengthVal = $(element).attr('data-validation-minLength');
                var $inputValLength = value.length;
                if ($inputValLength < parseInt($lengthVal)) {
                    return false;
                } else {
                    return true;
                }
            }
        );

        // Validation method for max length
        $.validator.addMethod(
            'maxLengthCheck',
            function(value, element) {
                var $lengthVal = $(element).attr('data-validation-maxLength');
                var $inputValLength = value.length;
                if ($inputValLength > parseInt($lengthVal)) {
                    return false;
                } else {
                    return true;
                }
            }
        );


        // Validation method for date of birth
        $.validator.addMethod(
            'validBirthDate',
            function(value, element) {
                var date = value;
                var dateSplit = date.split('/');

                function isValidDate2(y, m, d) {
                    // No leap year by default
                    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

                    // Check for leap (if evenly divisible by 4)
                    if (((y % 4 === 0) && (y % 100 !== 0)) || (y % 400 === 0)) {
                        daysInMonth[1] = 29;
                    }
                    return d <= daysInMonth[--m];
                }

                if (
                    (isValidDate2(dateSplit[0], dateSplit[1], dateSplit[2]) === false) || (dateSplit[0] === '' || dateSplit[1] === '' || dateSplit[2] === '')
                ) {
                    $('.birthday-selects').find('.dk-selected').each(function() {
                        $(this).addClass('select-error');
                    });
                    $('.birthday-selects').find('select').each(function() {
                        $(this).addClass('error');
                    });
                    return false;
                } else {
                    $('.birthday-selects').find('.dk-selected').each(function() {
                        $(this).removeClass('select-error');
                    });
                    $('.birthday-selects').find('select').each(function() {
                        $(this).removeClass('error');
                    });
                    return true;
                }
            }
        );

        // Validation method for minimum age
        $.validator.addMethod(
            'minAge',
            function(value, element, min) {
                var today = new Date();
                var birthDate = new Date(value);
                var age = today.getFullYear() - birthDate.getFullYear();
                if (age > min - 1) {
                    $('.birthday-selects').find('.dk-selected').each(function() {
                        $(this).removeClass('select-error');
                    });
                    $('.birthday-selects').find('select').each(function() {
                        $(this).removeClass('error');
                    });
                    return true;
                } else {
                    $('.birthday-selects').find('.dk-selected').each(function() {
                        $(this).addClass('select-error');
                    });
                    $('.birthday-selects').find('select').each(function() {
                        $(this).addClass('error');
                    });
                    return false;
                }
            }
        );


        // Allow empty fields
        $.validator.addMethod(
            'allowEmpty',
            function(value, element) {
                var $inputValLength = value.length;
                if ($inputValLength === 0) {
                    return true;
                }
            }
        );
    },

    // Trigger validation for each form
    validateForms: function() {

        $('form').each(function() {
            var $form = $(this);
            $form.validate({
                ignore: [],

                submitHandler: function(form) {

                    $("#ggReCaptchaMsg").remove();
                    var ggid = parseInt($(form).attr('captcha_wid_id')) + 1
                    if (ggid && grecaptcha.getResponse(ggid - 1) !== "") {
                        $form.find('input[type="submit"]').prop('disabled', true);
                        if ($form.hasClass('elemcajaxsubmit')) {
                            validation.mailchimpAjaxSubmit($form);
                        } else {
                            form.submit();
                            if ($form.attr('id') == 'ContactusForm') {
                                $('body').addClass('fixed');
                            }
                        }
                    } else if (!ggid && ggid != 0) {

                        if ($form.hasClass('elemcajaxsubmit')) {
                            $.ajax({
                                type: $form.attr('method'),
                                url: $form.attr('action'),
                                data: $form.serialize(),
                                cache: false,
                                //dataType: 'json',
                                //contentType: 'application/json; charset=utf-8',
                                success: function(data) {
									$(".thanksmsgajax").html(data);
                                    $form.siblings('.news-subscriber.thank-msg').show();
                                    $('body').addClass('subs-thanksmsg');
                                    $form.hide();

                                    // Added by Owen: if `form` has `data-onsuccess="reload"`, then successful submission will reload the page after 3 seconds. Unsuccessful submission that still returns HTML (such as an error message) should include the string `ajax-error`
                                    if ($form.data("onsuccess") === "reload" && data.indexOf("ajax-error") == -1)
                                    {
                                        let delay = $form.data("onsuccess-delay");
                                        if (isNaN(delay)) { delay = 3; }
                                        setTimeout(function () {
                                            window.location.reload(1);
                                        }, delay * 1000);
                                    }
                                    else if ($form.data("onerror") === "reload" && data.indexOf("ajax-error") != -1)
                                    {
                                        let delay = $form.data("onerror-delay");
                                        if (isNaN(delay)) { delay = 5; }

                                        setTimeout(function () {
                                            window.location.reload(1);
                                        }, delay * 1000);
                                    }
                                    else if ($(window).width() < window.tablet)
                                    {
                                        $('html,body').animate({
                                        scrollTop: $("#newssignupform").offset().top},
                                        'slow');
                                    }
                                }
                            });
                        } else {
                            form.submit();
                        }
                    } else {
                        var captchaValidationMsg = $(".g-recaptcha").attr('data-validation-captcha-msg');
                        $(".g-recaptcha").after("<span id='ggReCaptchaMsg' class='recaptcha-error-msg error'>" + captchaValidationMsg + "</span>");
                    }
                }
            });
        });
    },

    // Set validation rules for different types input sets
    setRules: function() {
        // Check required
        $('input').each(function() {
            var message_required = $(this).attr('data-validation-empty');
            if (message_required) {
                $(this).rules('add', {
                    required: true,
                    messages: {
                        required: message_required
                    }
                });
            }
        });

        // Check required
        $('textarea').each(function() {
            var message_required = $(this).attr('data-validation-empty');
            if (message_required) {
                $(this).rules('add', {
                    required: true,
                    messages: {
                        required: message_required
                    }
                });
            }
        });

        // Regex input formats
        $('[data-validation-regex]').each(function() {
            var message_format = $(this).attr('data-validation-format');
            $(this).rules('add', {
                regexFormat: true,
                messages: {
                    regexFormat: message_format
                }
            });
        });

        // Re-enter inputs matching (email and password)
        $('[data-validation-matchTo]').each(function() {
            var matchTo = $(this).attr('data-validation-matchTo');
            var message_matchTo = $(this).attr('data-validation-format');
            $(this).rules('add', {
                matchInput: true,
                messages: {
                    matchInput: message_matchTo
                }
            });
        });

        // Minimum length inputs
        $('[data-validation-minLength]').each(function() {
            var minLength_message = $(this).attr('data-validation-minLenght-message');
            $(this).rules('add', {
                minLengthCheck: true,
                messages: {
                    minLengthCheck: minLength_message
                }
            });
        });

        // Maximum length inputs
        $('[data-validation-maxLength]').each(function() {
            var maxLength_message = $(this).attr('data-validation-maxLenght-message');
            $(this).rules('add', {
                maxLengthCheck: true,
                messages: {
                    maxLengthCheck: maxLength_message
                }
            });
        });

        // Dropdowns
        $('select[data-validation-empty]:not([multiple="multiple"])').each(function() {
            var message_required = $(this).attr('data-validation-empty');
            $(this).rules('add', {
                selectRequired: true,
                messages: {
                    selectRequired: message_required
                }
            });
        });

        $('select[data-validation-empty][multiple="multiple"]').each(function() {
            var message_required = $(this).attr('data-validation-empty');
            $(this).rules('add', {
                multiselectRequired: true,
                messages: {
                    multiselectRequired: message_required
                }
            });
        });

        // Input type Number Disable "E"
        $(function() {
            $(':input[type="number"]').keypress(function(event) {
                if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
                    return false;
                }
            });
        });

        // Allow only characters
        $('._onlyChar').bind('keyup blur', function() {
            var node = $(this);
            node.val(node.val().replace(/[^àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœa-zA-Z- ]/g, ''));
        });

        // Disable negative value in Input type number
        // var numInput = document.querySelector(':input[type="number"]');
        // numInput.addEventListener(':input[type="number"]', function(){
        //     var num = this.value.match(/^\d+$/);
        //     if (num === null) {
        //         this.value = "";
        //     }
        // }, false)

        // Validate Date Of Birth
        $('.dob-hidden').each(function() {
            var message_valid = $(this).attr('data-validation-dob');
            $(this).rules('add', {
                validBirthDate: true,
                messages: {
                    validBirthDate: message_valid
                }
            });
        });

        // Validate Date Of Birth
        $('.dob-hidden').each(function() {
            var message_minage = $(this).attr('data-validation-minage');
            $(this).rules('add', {
                minAge: 17,
                messages: {
                    minAge: message_minage
                }
            });
        });

        function populateDob() {
            var $container = $('.birthday-selects');
            var $day = $container.find('#DobDay');
            var $month = $container.find('#DobMonth');
            var $year = $container.find('#DobYear');
            // get values
            var $dayVal = $day.val().toString();
            var $monthVal = $month.val().toString();
            var $yearVal = $year.val().toString();

            $('.dob-hidden').val($yearVal + '/' + $monthVal + '/' + $dayVal);
        }

        if ($('.birthday-selects').length) {
            populateDob();
        }

        $('.birthday-selects select').each(function() {
            $(this).on('change', function() {
                var wrapper = $(this).closest('.select-wrapper');
                populateDob();

                $('.dob-hidden').focus();
                setTimeout(function() {
                    $('.dob-hidden').blur();
                    var customDd = wrapper.find('.dk-selected');
                    if (customDd.length) {
                        customDd.focus();
                    } else {
                        wrapper.find('select');
                    }
                }, 50);
            });
        });

        $('[data-date-valid]').each(function(idx, el) {
            //_setDate(el)
        });

        $('.date-dd, .date-mm, .date-yy').change(function() {
            var el = $(this).closest('[data-date-valid]')

            _setDate(el)
        })

        function _setDate(el) {
            console.log(el.data('validate-format').split(','))

            var selectors = el.data('validate-format').split(',');

            el = $(el)
            var $values = []
            for (var i in selectors) {
                $values[i] = el.find('select.' + selectors[i]).val();
            }

            for (i in $values) {
                if ($values[i] == '') {
                    el.find('.date-value').val('').valid();
                    el.find('.dk-selected').addClass('select-error')
                    return false;
                }

            }
            var $date_joioned = $values.join('/');

            el.find('.date-value').val($date_joioned).valid()
            el.find('.dk-selected').removeClass('select-error');

        }

        $('.birthday-selects select').each(function() {
            $(this).on('change', function() {
                var wrapper = $(this).closest('.select-wrapper');
                populateDob();

                $('.dob-hidden').focus();
                setTimeout(function() {
                    $('.dob-hidden').blur();
                    var customDd = wrapper.find('.dk-selected');
                    if (customDd.length) {
                        customDd.focus();
                    } else {
                        wrapper.find('select');
                    }
                }, 50);
            });
        });

        // Allow empty fields
        $('[data-validation-allowEmpty]').each(function() {
            $(this).rules('add', {
                allowEmpty: true
            });
        });
    },
    mailchimpAjaxSubmit: function($form) {
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            //dataType: 'json',
            //contentType: 'application/json; charset=utf-8',
            success: function(data) {
				$(".thanksmsgajax").html(data);
                $(".news-subscriber.thank-msg").show();

                $('#signupform').hide();
                //if (data.result === 'success') {
                //    $('.popup-section.op_thanks_1').addClass('show');
                //    $('body').addClass('fixed');
                //    $form.find('input.button').prop('disabled', false);
                //    $form[0].reset();
                //} else if (data.result === 'error') {
                //    $('.popup-section.op_thanks_1').addClass('show');
                //    $('.popup-section.op_thanks_1 h1').text('Error');
                //    $('.popup-section.op_thanks_1 p').html(data.msg);
                //    setTimeout(function() {
                //        $('.popup-section.op_thanks_1 p a').addClass('mailchimpLink');
                //        $('.popup-section.op_thanks_1 p a').attr('target', '_blank');
                //    }, 400)
                //    $('body').addClass('fixed');
                //    $form.find('input.button').prop('disabled', false);
                //    $form[0].reset();
                //    //$('.popup-section.op_thanks_1 .homebutton').after().append('<div class="newmessage"><a>Send a new message</a></div>')
                //}
            }
        })
    },
    init: function() {
        if ($('form').length) {
            validation.setMethods();
            validation.validateForms();
            validation.setRules();
        }
    }
};
var videoPopup = {
    videoOpenBtn: $('#showreelButton'),
    body: $('body'),
    videoFrame: $('.fullscreen-video-wrapper'),
    vimeoApi: function() {
        var player = $('#showreel');
        var playerOrigin = '*';
        var status = $('.status');

        // Listen for messages from the player
        if (window.addEventListener) {
            window.addEventListener('message', onMessageReceived, false);
        } else {
            window.attachEvent('onmessage', onMessageReceived, false);
        }

        // Handle messages received from the player
        function onMessageReceived(event) {
            // Handle messages from the vimeo player only
            if (!(/^https?:\/\/player.vimeo.com/).test(event.origin)) {
                return false;
            }

            if (playerOrigin === '*') {
                playerOrigin = event.origin;
            }

            var data = JSON.parse(event.data);

            switch (data.event) {
                case 'ready':
                    onReady();
                    break;

                case 'playProgress':
                    onPlayProgress(data.data);
                    break;

                case 'pause':
                    onPause();
                    break;

                case 'finish':
                    onFinish();
                    break;
            }
        }

        // Helper function for sending a message to the player
        function post(action, value) {
            var data = {
                method: action
            };

            if (value) {
                data.value = value;
            }

            var message = JSON.stringify(data);
            player[0].contentWindow.postMessage(data, playerOrigin);
        }

        function onReady() {
            post('addEventListener', 'pause');
            post('addEventListener', 'finish');
            post('addEventListener', 'playProgress');
        }

        function onPause() {
            // Do nothing, for now
        }

        function onFinish() {
            $('.fullscreen-video-wrapper').remove();
        }

        function onPlayProgress(data) {
            // Do nothing, for now
        }
    },
    popupVideo: function() {

        function videoinitilization() {
            videoPopup.videoOpenBtn.on('click', function(event) {
                var vimeoId = $(this).attr('data-vimeo-id');
                var vimeoColour = $(this).attr('data-vimeo-colour');
                var windowWidth = $(window).width();
                var windowHeight = $(window).height();

                var videoElement = '<div class="fullscreen-video-wrapper">' +
                    '<div class="video-content">' +
                    '<div class="close-fullscreen-video" id="showReelCloseButton"></div>' +
                    '<iframe class="fullscreen-video" id="showreel" ' +
                    'src="https://player.vimeo.com/video/' + vimeoId + '?dnt=1&autoplay=1&color=' + vimeoColour +
                    '&api=1&player_id=showreel' + '&title=0&byline=0&portrait=0" ' +
                    'width="' + windowWidth + '" height="' + windowHeight + '" frameborder="0"' +
                    'webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
                    '</div>' +
                    '</div>'

                $('body').prepend(videoElement);
                $('.fullscreen-video-wrapper').hide().fadeIn(100);
                $('body').addClass('no-reload');
                videoPopup.vimeoApi();
            });

            $('body').on('click', '#showReelCloseButton', function(event) {
                $('.fullscreen-video-wrapper').fadeOut(300, function() { $(this).remove(); })
                $('body').removeClass('no-reload');
            });
        }
        videoinitilization();

    },

    init: function() {
        videoPopup.popupVideo();
    }
};
var workShare = {
    shareBtn: $('.full-cta.share-button'),
    shareParent: $('.share_btn'),

    share: function() {
        function socialShareBtn() {
            workShare.shareBtn.hover(function() {
                workShare.shareParent.addClass('open-social-btns');
            });
            $.fn.isInViewport = function() {
                var elementTop = $(this).offset().top;
                var elementBottom = elementTop + $(this).outerHeight();

                var viewportTop = $(window).scrollTop();
                var viewportBottom = viewportTop + $(window).height();

                return elementBottom > viewportTop && elementTop < viewportBottom;
            };
            if (window.windowWidth <= 1024) {
                var element=$('.i_w_007_full_cta_block.share_btn');
                if (element.length)
                {
                    $(window).on('resize scroll', function() {
                        if (element.isInViewport()) {
                            setTimeout(function() {
                                workShare.shareParent.addClass('open-social-btns');
                            }, 1000);
                            
                        } else {
                        }
                    });
                }
            }
        }
        socialShareBtn();

    },

    init: function() {
        workShare.share();
    }
};
var workVideo = {
    videoOpenBtn: $('.video-content .vimeo-video-open-btn'),
    body: $('body'),

    iframeVideo: function() {
        function videoinitilization() {
            workVideo.videoOpenBtn.on('click', function(event) {
                var vimeoId = $(this).closest('.video-content').attr('data-vimeo-id');
                var vimeoColour = $(this).closest('.video-content').attr('data-vimeo-colour');
                var windowWidth = $(window).width();
                var windowHeight = $(window).height();

                var videoElement = '<div class="video-frame-block">' +
                    '<div class="video-close-btn" id="videoCloseButton">' +
                    '</div>' +
                    '<iframe class="fullscreen-video" id="showreel" ' +
                    'src="https://player.vimeo.com/video/' + vimeoId + '?dnt=1&autoplay=1&color=' + vimeoColour +
                    '&api=1&player_id=showreel' + '&title=0&byline=0&portrait=0" ' +
                    'width="' + windowWidth + '" height="' + windowHeight + '" frameborder="0" ' +
                    'webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
                    '</div>'

                $(this).closest('._vimeoVideoBlock').prepend(videoElement);
                $(this).addClass('posterHide');
                $('body').addClass('no-reload');
                videoPopup.vimeoApi();

            });

            $('body').on('click', '.video-close-btn', function(event) {
                $(this).closest('.video-frame-block').fadeOut(500, function() { $(this).remove(); })
                console.log($(this).parent().parent());
                $(this).closest('._vimeoVideoBlock').find('.vimeo-video-open-btn.posterHide').removeClass('posterHide');
                $('body').removeClass('no-reload');
            });

        }
        videoinitilization();

    },

    init: function() {
        workVideo.iframeVideo();
    }
};
//  ***********************
//  $$ Document ready
//  ***********************
window.windowWidth = window.innerWidth;
window.windowHeight = window.innerHeight;

window.isiPhone = navigator.userAgent.toLowerCase().indexOf('iphone');
window.isiPad = navigator.userAgent.toLowerCase().indexOf('ipad');
window.isiPod = navigator.userAgent.toLowerCase().indexOf('ipod');
window.isifirefox = navigator.userAgent.toLowerCase().indexOf('firefox');
window.isIEBrowser = navigator.userAgent.toLowerCase().indexOf('edge') || navigator.userAgent.toLowerCase().indexOf('trident') || navigator.userAgent.toLowerCase().indexOf('msie');
window.tablet = 640;
window.tabletWide = 800;
window.desktop = 1024;
window.tab = 768;

// remove elements
if (jQuery(window).outerWidth(true) >= tabletWide) {
    jQuery('._mobile-only').remove();
} else {
    jQuery('._desktop-only').remove();
}

// Custom variables
var lastScrollTop = 0;

// example.js
example.init();
gaUtility.init();
menu.init();
newsletter.init();
getInTouch.init();
form.init();
validation.init();
fullpage.init();
selects.init();
filters.init();
customSelect.init();
characterLimit.init();
videoPopup.init();
// heroVideo.init();
getInTouchSorting.init();
workVideo.init();
workShare.init();
googlemapBlock.init();
slider.init();
joblisting.init();
jobsBamboo.init();
mappin.init();
contentGate.init();
//  ***********************
//  $$ Smart resize
//  ***********************
(function ($, sr) {
	var debounce = function (func, threshold, execAsap) {
		var timeout;
		return function debounced() {
			var obj = this,
				args = arguments;
			function delayed() {
				if (!execAsap) {
					func.apply(obj, args);
				}
				timeout = null;
			}
			if (timeout) {
				clearTimeout(timeout);
			} else if (execAsap) {
				func.apply(obj, args);
			}
			timeout = setTimeout(delayed, threshold || 500);
		};
	};
	jQuery.fn[sr] = function (fn) {
		return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
	};
})(jQuery, 'smartresize'); // End smartresize


$(window).smartresize(function () {
	window.newWindowWidth = window.innerWidth;
	window.newWindowHeight = window.innerHeight;

	//	responsive-helpers.js


	window.windowWidth = window.innerWidth;
	window.windowHeight = window.innerHeight;
});
});// jQuery end
// no writing in this file